export enum Size {
  Small,
  Medium,
  Big,
  Huge,
}
export enum LoginType {
  BSC,
  Terra,
  Email,
  Avalanche,
  Facebook,
  Google,
  GoogleMobile,
  SavedToken,
  Okex,
  Apple,
  Twitter,
  Farmer,
}

export enum AuthState {
  Metamask,
  Terra,
  Email,
  Farmer,
}
export enum CustomerRole {
  Owner,
  Farmer,
  Scholar,
}
export enum UserRole {
  Owner,
  Farmer
}
export interface UserEntity {
  "A": number, // "id",
  "B": boolean, // "in_battle",
  "C": string, // "user_name",
  "D": string, // "name",
  "E": number, // "avatar",
  "F": number, // "progress",
  "G": number, // "food",
  "H": number, // "mag",
  "I": number, // "mstr",
  "J": number, // "evolution_items",
  "K": number, // "energy",
  "L": number, // "nm_st",
  "M": number, // "rr_st",
  "N": number, // "el_st",
  "O": number, // "nmr_st",
  "P": number, // "rrr_st",
  "Q": number, // "elr_st",
  "R": number, // "active_land_id",
  "S": number, // "exp",
  "T": boolean, // "is_block",
  "U": number, // "level",
  "V": number, // "level_reward",
  "X": number, // referral_customer_id
  "Y": Date, // referral_last_submit
  "Z": number, // ticket_co
  "AA": number, // ticket_uc
  "AB": number, // ticket_ra
  "AC": number, // ticket_ep
  "AD": number, // ticket_le
  "AE": Date, // created_at
  "AF": number, // referred_claimed_rewards
  "AJ": number, // staked_mag
  "BA": Date // scholar_expired_at,
  "BG": number // pigment,
  "BL": number // elective_st_co,
  "BM": number // elective_rune_st_co,
  "BN": number // elective_st_uc,
  "BO": number // elective_rune_st_uc,
  "BP": number // elective_st_ra,
  "BQ": number // elective_rune_st_ra,
  "BR": number // elective_st_ep,
  "BS": number // elective_rune_st_ep,
  "BT": number // elective_st_le,
  "BU": number // elective_rune_st_le,
  "BV": number // spent_mstr,
  "BW": Date // expired_newbie_blessing,
  "BX": any // nft_points,
  "BY": any // max_token_capacity,
  "BZ": number // elixir,
  "CA": number // mutated_gen,
  "AO": number // scholar_owner
}
export interface AuthEntity {
  "A": number, // "id",
  "B": number, // "customer_id",
  "C": string, // "identity",
  "D": number, // "type",
  "E": number, // "role",
  "F": string, // "password",
  "G": boolean, // "is2FARequired",
  "H": string, // password_last_update
  "I": boolean, // is_2fa_enabled
  mstr_wallet: number,
  mag_wallet: number,
}

export enum ButtonColor {
  'red',
  'orange',
  'blue',
  'yellow',
  'gray',
  'purple',
  'brown'
}

export enum ButtonVariant {
  'primary',
  'outline',
}

export enum MongenState {
  Egg,
  Idle,
  Breeding,
  Working,
  Traning,
  Battle,
}

export enum BodyPart {
  Form,
  Head,
  Eyes,
  Horns,
  Tail,
  Back,
  FrontLeg,
  BackLeg,
  Mouth,
  Aura,
}

export enum PlotType {
  LandCore,
  Pasture,
  Breeding,
  Hatching,
  Production,
  Storage,
  Camp,
  Tower,
  Bombard,
  Trap,
  Barracks,
  Decor,
  All,
}


export enum TrapType {
  Default,
  Poison,
  Slowed,
  Paralyzed,
  Confused,
  Bleeding,
  Silence,
  Burned,
  MG,
  STD,
  AGR,
}

export enum BombardType {
  Default,
  RNG,
  ENG,
}

export enum BarrackType {
  Default,
  Food,
}

export enum TowerType {
  Default,
  RNG,
  CD,
}

export enum CampType {
  Default,
  HP,
  MG,
  STD,
  AGR,
}

export enum Token {
  MAG,
  MSTR,
}

export enum Rarity {
  Common,
  Uncommon,
  Rare,
  Epic,
  Legendary,
  All,
}

export enum ScholarItemStatus {
  All,
  Available,
  OnSale,
  InUse,
  Lending,
  Banned,
  Waiting,
  UsedByScholar
}

export enum ScholarItemStatusDisplay {
  "All",
  "Available",
  "On Sale",
  "In Use",
  "Lending",
  "Banned",
  "Waiting",
  "In Use By Scholar"
}

export enum ScholarItemType {
  Soulcore,
  Mongen,
  Plot,
  All
}

export enum MongenRace {
  Beast,
  Tectos,
  Mystic,
  Celest,
  Chaos,
  All,
}
export interface GasPrice {
  denom: string,
  price: number,
}

export enum Mode {
  Scholars,
  Clans,
}

export enum ScholarPermission {
  EditLand,
  CreateBluePrint,
  ConvertNft,
  ConvertItems,
  Hatch,
  Breed,
  Feed,
  Evolve,
  ExtractEvo,
  EditSkill,
  UpgradePlot,
  TrainTroops
}

export enum Feature {
  ALL,
  DAO_LOGIN,
  DAO_STAKE_MSTR,
  DAO_STAKE_MAG,
  DAO_IMPORT_FT,
  DAO_UNSTAKE_MSTR,
  DAO_UNSTAKE_MAG,
  DAO_CONVERT_FT,
  DAO_REFERRAL_EVENT,
  DAO_REFERRAL_SUBMIT,
  DAO_FAUCET,
  DAO_CLAIM_REWARD,
  DAO_RESTAKE_REWARD,
  GAMEPLAY_LOGIN,
  GAMEPLAY_STAKE_MAG,
  GAMEPLAY_STAKE_MSTR,
  GAMEPLAY_CONVERT_NFT,
  GAMEPLAY_CONVERT_FT,
  GAMEPLAY_IMPORT_NFT,
  GAMEPLAY_IMPORT_FT,
  GAMEPLAY_REFILL_ENERGY,
  GAMEPLAY_MAIL,
  GAMEPLAY_ADVENTURE,
  GAMEPLAY_BATTLEFRONT,
  GAMEPLAY_BOSS_CHALLENGE,
  GAMEPLAY_ARENA,
  GAMEPLAY_INVENTORY,
  GAMEPLAY_BUILD,
  GAMEPLAY_MONGEN_INFO,
  GAMEPLAY_CLAIM_DAILY_QUEST,
  SUPPORT_LOGIN,
  SCHOLAR_LOGIN,
  GAMEPLAY_EDIT_LAND,
  GAMEPLAY_CREATE_BLUE_PRINT,
  GAMEPLAY_HATCH_EGG,
  GAMEPLAY_BREED,
  GAMEPLAY_FEED_FOOD,
  GAMEPLAY_EVOLVE,
  GAMEPLAY_EXTRACT_EVO,
  GAMEPLAY_EDIT_SKILL,
  GAMEPLAY_UPGRADE_PLOT,
  GAMEPLAY_TRAINING_TROOPS,
  GAMEPLAY_EVENT_BUTTON,
  GAMEPLAY_WRITE_LOG,
  BATTLE_SHARE_GAME,
  MINIGAME,
  GAMEPLAY_SKIP_PLOT,
  GAMEPLAY_UPGRADE_RUNE,
  GAMEPLAY_ABYSS,
  LEGEND_ISLA_LOGIN,
  CLICK2EARN_FUSION,
  CLICK2EARN_MUTATION
}

export enum TransactionType {
  MAG,
  MSTR,
  EXP,
  FOOD,
  EVOLITEM,
  NormalStone,
  RerollStone,
  EliteStone,
  NormalRuneStone,
  RerollRuneStone,
  EliteRuneStone,
}

export enum NFTType {
  Mongen,
  SoulCore,
  Land,
  Plot,
  EvolutionItem,
  NormalStone,
  RerollStone,
  EliteStone,
  NormalRuneStone,
  RerollRuneStone,
  EliteRuneStone,
  TicketCO,
  TicketUC,
  TicketRA,
  TicketEP,
  TicketLE,
  TicketGE,
  TicketGL,
  Pigment,
  Box,
  ElectiveStoneCO,
  ElectiveRuneStoneCO,
  ElectiveStoneUC,
  ElectiveRuneStoneUC,
  ElectiveStoneRA,
  ElectiveRuneStoneRA,
  ElectiveStoneEP,
  ElectiveRuneStoneEP,
  ElectiveStoneLE,
  ElectiveRuneStoneLE,
  Elixir,
  MutatedGen,
  Rune,
}

export enum CustomerInterface {
  id = "A",
  in_battle = "B",
  user_name = "C",
  name = "D",
  avatar = "E",
  progress = "F",
  food = "G",
  mag = "H",
  mstr = "I",
  evolution_items = "J",
  energy = "K",
  nm_st = "L",
  rr_st = "M",
  el_st = "N",
  nmr_st = "O",
  rrr_st = "P",
  elr_st = "Q",
  active_land_id = "R",
  exp = "S",
  is_block = "T",
  level = "U",
  level_reward = "V",
  fame = "W",
  referral = "X",
  referral_last_submit = "Y",
  ticket_co = "Z",
  ticket_uc = "AA",
  ticket_ra = "AB",
  ticket_ep = "AC",
  ticket_le = "AD",
  created_at = "AE",
  referred_claimed_rewards = "AF",
  adventure_star_reward = "AG",
  adventure_star = "AH",
  count_refill_energy = "AI",
  staked_mag = "AJ",
  earn_mag = "AK",
  collected_plots = "AL",
  souldcore_hatched = "AM",
  breeding_count = "AN",
  scholar_owner = "AO",
  scholar_permissions = "AP",
  scholar_address = "AQ",
  ticket_ge = "AR",
  ticket_gl = "AS",
  level_reward_info = "AT",
  earn_ft = "AU",
  avatar_pool = "AV",
  sharing_rate = "AW",
  expect_earning = "AX",
  scholar_id = "AY",
  max_capacity = "AZ",
  scholar_expired_at = "BA",
  energy_event = "BB",
  count_buy_energy_event = "BC",
  battlefront_mongen_team = "BD",
  boss_mongen_team = "BE",
  bonus_tuts = "BF",
  pigment = "BG",
  action_points = "BH",
  curse_effect_receive = "BI",
  bless_receive_list = "BJ",
  emoji_pool = "BK",
  elective_st_co = "BL",
  elective_rune_st_co = "BM",
  elective_st_uc = "BN",
  elective_rune_st_uc = "BO",
  elective_st_ra = "BP",
  elective_rune_st_ra = "BQ",
  elective_st_ep = "BR",
  elective_rune_st_ep = "BS",
  elective_st_le = "BT",
  elective_rune_st_le = "BU",
  spent_mstr = "BV",
  expired_newbie_blessing = "BW",
  nft_points = "BX",
  max_token_capacity = "BY",
  elixir = "BZ",
  mutated_gen = "CA",
  dao_stake_point = "CE"// dao_stake_point [rarity]

}

export enum AuthInterface {
  id = "A",
  customer_id = "B",
  identity = "C",
  type = "D",
  role = "E",
  password = "F",
  is2FARequired = "G",
  password_last_update = "H",
  is_2fa_enabled = "I",
}


export enum MongenInterface {
  id = "A",
  name = "B",
  dna = "C",
  customer_id = "D",
  plot_id = "E",
  is_attach = "F",
  level = "G",
  next_level_progress = "H",
  mongen_state = "I",
  evolution = "J",
  is_lock = "K",
  is_free = "L",
  evolution_points = "M",
  land_id = "N",
  births = "O",
  basic_skill = "P",
  ultimate_skill = "Q",
  basic_skill_list = "R",
  ultimate_skill_list = "S",
  is_tut = "T",
  troop_list = "U",
  training_queue = "V",
  freeze_until = "W",
  strategy = "X",
  is_deassigning = "Y",
  mongen_battle = "Z",
  battle_count = "AA",
  acc_battle = "AB",
  win_count = "AC",
  lose_count = "AD",
  fed_food = "AE",
  fed_evolve = "AF",
  fed_stones = "AG",
  loyalty = "AH",
  morale = "AI",
  morale_eff_value = "AJ",
  is_high_fee = "AM",
  scholar_vault = "AT"
}

export enum PlotInterface {
  id = "A",
  name = "B",
  customer_id = "C",
  plot_type = "D",
  x = "E",
  y = "F",
  size_x = "G",
  size_y = "H",
  plot_race = "I",
  mongen_race = "J",
  rarity = "K",
  level = "L",
  breeding_time = "M",
  hatching_time = "N",
  produce_time = "O",
  upgrade_time = "P",
  production_mode = "Q",
  is_attach = "R",
  attach_mongens = "S",
  is_lock = "T",
  is_free = "U",
  land_id = "V",
  is_land_active = "W",
  training_time = "X",
  freeze_until = "Y",
  is_deassigning = "Z",
  decoration_list = "AB",
  sub_plot_type = "AD",
  is_high_fee = "AH",
  scholar_vault = "AI",
}

export enum CaptchaStatus {
  NEEDED,
  SHOWING,
  VERIFIED,
}

export type SSO_TYPE = {
  DAOLink: string;
  GuideLink: string;
  DaoGuildLink: string;
  LiteLink: string;
  GameLink: string;
  SupportLink: string;
  WhitePaperLink: string;
  TerraChainId: string;
  BattleLink: string;
  PortalLink: string;
  BridgeLink: string;
  GuildLink: string;
  EventLink: string;
  MarketLink: string;
  DocsLink: string;
  MonstvLink: string;
  MonsterraverseLink: string;
  LandingPage: string;
  MetamaskExtension: string;
  TerraExtension: string;
  contract: any;
  network: any;
  GOOGLE_CLIENT_ID: string;
  FACEBOOK_APP_ID: string;
  CAPTCHA_CLIENT_ID: string;
  S3Host: string;
  price: {
    BNB: string;
    AVAX: string;
    LUNA: string;
    UST: string;
    OKT: string;
  };
};

export enum CustomerResource {
  Food,
  LOCK_MAG,
  MSTR,
  EvolutionItem,
  NormalStone,
  RerollStone,
  EliteStone,
  NormalRuneStone,
  RerollRuneStone,
  EliteRuneStone,
  Experience,
  TicketCO,
  TicketUC,
  TicketRA,
  TicketEP,
  TicketLE,
  UNLOCK_MAG,
  TicketGE,
  TicketGL,
  Energy,
  Pigment,
  ActionPoint,
  BATTLEPASS_EXP,
  ElectiveStoneCO,
  ElectiveRuneStoneCO,
  ElectiveStoneUC,
  ElectiveRuneStoneUC,
  ElectiveStoneRA,
  ElectiveRuneStoneRA,
  ElectiveStoneEP,
  ElectiveRuneStoneEP,
  ElectiveStoneLE,
  ElectiveRuneStoneLE,
  Elixir,
  MutatedGen,
  BATTLEPASS_EXP_ARENA,
  AbyssStar,
}

export const ResourceTypeDisplay = {
  "Food": "Food",
  "LOCK_MAG": "sMAG",
  "MSTR": "MSTR",
  "EvolutionItem": "Evolution Item",
  "NormalStone": "Normal Stone",
  "RerollStone": "Reroll Stone",
  "EliteStone": "Elite Stone",
  "NormalRuneStone": "Normal Rune Stone",
  "RerollRuneStone": "Reroll Rune Stone",
  "EliteRuneStone": "Elite Rune Stone",
  "TicketCO": "Common Ticket",
  "TicketUC": "Uncommon Ticket",
  "TicketRA": "Rare Ticket",
  "TicketEP": "Epic Ticket",
  "TicketLE": "Legendary Ticket",
  "UNLOCK_MAG": "MAG",
  "TicketGE": "General Ticket",
  "TicketGL": "Global Ticket",
  "Energy": "Energy",
  "Pigment": "Pigment",
  "ElectiveStoneCO": "Common Elective Stone",
  "ElectiveRuneStoneCO": "Common Elective Rune Stone",
  "ElectiveStoneUC": "Uncommon Elective Stone",
  "ElectiveRuneStoneUC": "Uncommon Elective Rune Stone",
  "ElectiveStoneRA": "Rare Elective Stone",
  "ElectiveRuneStoneRA": "Rare Elective Rune Stone",
  "ElectiveStoneEP": "Epic Elective Stone",
  "ElectiveRuneStoneEP": "Epic Elective Rune Stone",
  "ElectiveStoneLE": "Legendary Elective Stone",
  "ElectiveRuneStoneLE": "Legendary Elective Rune Stone",
  "Elixir": "Elixir",
  "MutatedGen": "Mutate Gen",
}

