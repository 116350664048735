import axios from "axios";
import Config, { SSO_META } from "config";
import { store } from "reducer/store";
import { bulkSetUserInfo, clearUserInfo } from "reducer/user.slice";
import { clearAuthInfos, setAuthInfos } from "reducer/wallet.slice";
import {
  AuthEntity,
  AuthInterface,
  CustomerRole,
  LoginType,
  NFTType,
  Rarity,
  ScholarItemStatus,
  TransactionType,
  UserEntity,
} from "interfaces";
import ui from "./ui";
import utils from "./utils";
import { setLoginType } from "reducer/logintype.slice";
import { clearSSOMeta, setSSOMeta } from "reducer/ssometa.slice";
import { setRole } from "reducer/role.slice";

//@ts-ignore
const Web3 = window.Web3;
//@ts-ignore
const ethereum = window.ethereum;
const web3 = new Web3(Web3.givenProvider);

// config api method
async function post(url: string, data: any): Promise<any> {
  let rs = await fetch(`${utils.formatHostUrl(Config.ApiHost)}${url}`, {
    method: "POST",
    mode: "cors",
    headers: {
      "Content-Type": "application/json; charset=UTF-8",
      Authorization: `Bearer ${sessionStorage.getItem("token")}`,
    },
    body: JSON.stringify(data),
  });
  switch (rs.status) {
    case 200:
      let tmp = await rs.json();
      return tmp;
    case 403:
      sessionStorage.clear();
      localStorage.clear();
      store.dispatch(clearUserInfo());
      store.dispatch(clearAuthInfos());
      ui.alertFailed("Your session is expired !", "Please sign in again !");
      throw new Error("forbidden");
    default:
      let err = await rs.json();
      throw err;
  }
}
async function get(url: string): Promise<any> {
  let rs = await fetch(`${utils.formatHostUrl(Config.ApiHost)}${url}`, {
    method: "GET",
    mode: "cors",
    headers: {
      "Content-Type": "application/json; charset=UTF-8",
      Authorization: `Bearer ${sessionStorage.getItem("token")}`,
    },
  });
  switch (rs.status) {
    case 200:
      let tmp = await rs.json();
      return tmp;
    case 403:
      sessionStorage.clear();
      localStorage.clear();
      throw new Error("forbidden");
    default:
      let err = await rs.json();
      throw err;
  }
}
async function postFormData(
  url: string,
  data: any,
  cb?: Function
): Promise<any> {
  var formdata = new FormData();
  for (var i in data) {
    formdata.append(i, data[i]);
  }
  let tmp: any = await axios.request({
    method: "post",
    url: `${utils.formatHostUrl(Config.ApiHost)}${url}`,
    data: formdata,
    headers: {
      Authorization: `Bearer ${sessionStorage.getItem("token")}`,
      Accept: "multipart/form-data",
    },
    onUploadProgress: (p) => {
      if (cb) cb(p);
    },
  });
  if (tmp.code === "forbidden") {
    window.location.href = "/auth";
    throw new Error("forbidden");
  }
  return tmp.data;
}

// authenticate handle
async function getMetamaskSign() {
  let nonce = await getNonce();
  const account = await ethereum.request({ method: "eth_requestAccounts" });
  let sign = await web3.eth.personal.sign(nonce, account[0], "publicpassword");
  return { nonce, sign };
}

function getChainId(type: LoginType) {
  switch (type) {
    case LoginType.BSC:
    case LoginType.Avalanche:
    case LoginType.Okex:
      return SSO_META().network[LoginType[type]].chainId;
    case LoginType.Terra:
      return "pisco-1";
  }
}

async function changeNetwork(type: LoginType) {
  // @ts-ignore
  await window.ethereum
    .request({
      method: "wallet_addEthereumChain",
      params: [SSO_META().network[LoginType[type]]],
    })
    .catch((error: any) => {
      console.log(error);
      throw error;
    });
}

async function checkNetwork(type: LoginType) {
  if (Config.AntiWalletAuth.includes(type)) return;
  // @ts-ignore
  const currentNetwork = await window.ethereum.request({
    method: "eth_chainId",
  });
  if (getChainId(type) !== currentNetwork) {
    await changeNetwork(type);
    // @ts-ignore
    const changedNetwork = await window.ethereum.request({
      method: "eth_chainId",
    });
    if (getChainId(type) !== changedNetwork) {
      throw new Error("failed to switch network");
    }
    store.dispatch(setLoginType(type));
  }
}

async function login(
  type: LoginType,
  social_access_token?: string,
  email?: string,
  password?: string
) {
  localStorage.clear();
  let nonce = await getNonce();
  await checkNetwork(type);
  let sign, account;
  switch (type) {
    case LoginType.BSC:
    case LoginType.Avalanche:
    case LoginType.Okex:
      account = await ethereum?.request({ method: "eth_requestAccounts" });
      sign = await web3.eth.personal.sign(nonce, account[0], "publicpassword");
      break;
  }
  let { authInfo, customerInfo, token, authInfos } = await api.post(
    "/scholar/login-scholar",
    {
      sign,
      nonce,
      type,
      social_access_token,
      email,
      password,
    }
  );
  let role: CustomerRole = authInfo[AuthInterface.role];
  store.dispatch(setRole(role));
  await api.setStateLogin(token, customerInfo, authInfos, type);
}

async function loginByPassword(email: string, password: string) {
  let {
    customerInfo,
    token,
    authInfos,
  }: {
    customerInfo: UserEntity;
    token: string;
    authInfos: AuthEntity[];
    is_2fa_enabled: boolean;
  } = await api.post("/scholar/login-scholar", {
    email,
    password,
    type: LoginType.Email,
  });
  await api.setStateLogin(token, customerInfo, authInfos, LoginType.Email);
}

async function setStateLogin(
  token: string,
  customerInfo: any,
  authInfos: any,
  loginType: LoginType,
  isWalletConnect: boolean = false
) {
  sessionStorage.setItem("token", token);
  let newLoginType = loginType;
  if (Config.AntiWalletAuth.includes(loginType)) {
    const existedWallet = authInfos.filter(
      (authInfo: any) =>
        !Config.AntiWalletAuth.includes(authInfo[AuthInterface.type])
    );
    if (existedWallet.length > 0) {
      let currentType = existedWallet[0][AuthInterface.type];
      newLoginType = currentType ?? LoginType.BSC ?? loginType;
    }
  }
  store.dispatch(setLoginType(newLoginType));
  if (isWalletConnect) {
    sessionStorage.setItem("isWalletConnect", "true");
  }
  store.dispatch(bulkSetUserInfo(customerInfo));
  store.dispatch(setAuthInfos(authInfos));
}

async function getNonce() {
  let rs = await api.post("/nonce/create-nonce", {});
  return rs.nonce;
}

async function uploadImage(image: any): Promise<any> {
  return postFormData(
    `${utils.formatHostUrl(Config.ApiHost)}/file/upload-image`,
    { image }
  );
}
async function getMessages(message: string) {
  return post("/message/get-dao-messages", { message });
}

async function getPublicConfig() {
  return get("/config/get-public-site");
}

async function createScholarAccount(
  username: string,
  password: string,
  walletAddress: string,
  sharingRate: object,
  expectEarning: object,
  scholarLoginType: LoginType
) {
  return post("/scholar/create-scholar-account", {
    username,
    password,
    walletAddress,
    ...sharingRate,
    ...expectEarning,
    loginType: scholarLoginType,
  });
}
async function getMAGFee(amount: number, chain: number) {
  return post("/convert/get-mag-fee-dao", { amount, chain });
}
async function getMSTRFee(amount: number, chain: number) {
  return post("/convert/get-mstr-fee-dao", { amount, chain });
}
async function loginTerra(sign: any, nonce: any, type: LoginType) {
  return post("/scholar/login-scholar", { sign, nonce, type });
}
async function getScholarsAccount({
  limit,
  offset,
  pattern,
}: {
  limit: number;
  offset: number;
  pattern?: string;
}) {
  return post("/scholar/get-scholar-accounts", {
    limit,
    offset,
    username: pattern,
  });
}
async function getItems({
  nft_type,
  rarity,
  limit,
  offset,
  status,
  scholar_id,
  viewMode,
}: {
  nft_type?: NFTType;
  rarity?: Rarity;
  limit: number;
  offset: number;
  status: ScholarItemStatus;
  scholar_id: number;
  viewMode: string;
}) {
  return post("/scholar/get-items", {
    nft_type,
    rarity,
    limit,
    offset,
    status,
    scholar_id,
    view_mode: viewMode,
  });
}
async function getScholarDetail(id: string) {
  return post("/scholar/get-scholar-detail", {
    scholar_id: Number(id),
  });
}
async function editScholarPermission(id: string, scholar_permissions: string) {
  return post("/scholar/edit-scholar-account", {
    scholar_id: Number(id),
    scholar_permissions,
  });
}
async function assignFt(
  id: string | number,
  type: TransactionType,
  amount: number
) {
  return post("/scholar/assign-assets", {
    scholar_id: Number(id),
    type,
    amount,
  });
}
async function unassignFt(
  id: string | number,
  type: TransactionType,
  amount: number
) {
  return post("/scholar/unassign-assets", {
    scholar_id: Number(id),
    type,
    amount,
  });
}
async function assignNft(id: string | number, type: NFTType, item_id: number) {
  return post("/scholar/assign-items", {
    scholar_id: Number(id),
    type,
    item_id,
  });
}
async function assignWaitingFn(
  id: string | number,
  type: NFTType,
  item_id: number
) {
  return post("/scholar/assign-waiting-items", {
    scholar_id: Number(id),
    type,
    item_id,
  });
}
async function unassignNft(
  id: string | number,
  type: NFTType,
  item_id: number
) {
  return post("/scholar/unassign-items", {
    scholar_id: id,
    type,
    item_id,
  });
}
async function getOwnerOverview() {
  return post("/scholar/get-owner-statistics", {});
}
async function deleteScholarAccount(scholar_id: any) {
  return post("/scholar/delete-scholar-account", {
    scholar_id,
  });
}
async function changeScholarPassword(scholar_id: any, password: string) {
  return post("/scholar/change-scholar-password", {
    scholar_id,
    password,
  });
}
async function getOwnerReport() {
  return post("/scholar/get-scholar-daily-report", {});
}
async function editScholarExpectEarning(
  scholar_id: string,
  expect_earning: object
) {
  return post("/scholar/edit-scholar-earning", {
    scholar_id,
    ...expect_earning,
  });
}
async function getSSOMeta() {
  const ssoMeta = await get("/customer/get-SSO-meta");
  store.dispatch(clearSSOMeta());
  store.dispatch(setSSOMeta(ssoMeta));
}

async function loginBySocial(social_access_token: string, type: LoginType) {
  let { customerInfo, token, authInfos } = await post(
    "/scholar/login-scholar",
    {
      social_access_token,
      type,
    }
  );
  await api.setStateLogin(token, customerInfo, authInfos, type);
}

async function exchangeVault(
  mongen_ids: number[],
  plot_ids: number[],
  rarity: Rarity,
  scholar_id: number
) {
  let data = await post("/scholar/exchange-vault", {
    mongen_ids,
    plot_ids,
    rarity,
    scholar_id,
  });
  return data;
}

async function takeOwnerRight(id: number, nftType: NFTType) {
  let data = await post("/scholar/take-owner-right", {
    id,
    type: nftType,
  });
  return data;
}

// export
const api = {
  takeOwnerRight,
  loginBySocial,
  getSSOMeta,
  editScholarExpectEarning,
  getOwnerReport,
  deleteScholarAccount,
  getOwnerOverview,
  assignNft,
  assignFt,
  editScholarPermission,
  getScholarDetail,
  getItems,
  loginTerra,
  getScholarsAccount,
  getPublicConfig,
  createScholarAccount,
  getMessages,
  setStateLogin,
  loginByPassword,
  getNonce,
  post,
  postFormData,
  uploadImage,
  login,
  getMetamaskSign,
  getMAGFee,
  getMSTRFee,
  unassignNft,
  checkNetwork,
  unassignFt,
  assignWaitingFn,
  exchangeVault,
  changeScholarPassword,
};
export default api;
